@import url(//fonts.googleapis.com/css?family=Open+Sans:400,300,300italic,400italic,600,600italic,700,700italic,800,800italic);
@import url(//fonts.googleapis.com/css?family=Lato:400,100,100italic,300,300italic,400italic,700,700italic,900,900italic);
@import url(//fonts.googleapis.com/css?family=Metrophobic);
@import url(//fonts.googleapis.com/css?family=Oxygen:400,300,700);
@import url(//fonts.googleapis.com/css?family=Merriweather:300,400);
@import url(//fonts.googleapis.com/css?family=Roboto);
@import url(//fonts.googleapis.com/css?family=Montserrat);

/* TextBook New */
@font-face {
  font-family: 'TextbookNew-Light';
  src: url('https://s3.amazonaws.com/springbot-assets/fonts/textbook-new/2AFAB3_0_0.eot');
  src: url('https://s3.amazonaws.com/springbot-assets/fonts/textbook-new/2AFAB3_0_0.eot?#iefix') format('embedded-opentype'),
       url('https://s3.amazonaws.com/springbot-assets/fonts/textbook-new/2AFAB3_0_0.woff') format('woff'),
       url('https://s3.amazonaws.com/springbot-assets/fonts/textbook-new/2AFAB3_0_0.ttf') format('truetype');
}

/* Bucket Fonts */
@font-face {
  font-family: 'bucket-ico-font';
  src:url('https://s3.amazonaws.com/springbot-assets/fonts/bucket-admin/bucket-ico-font.eot');
  src:url('https://s3.amazonaws.com/springbot-assets/fonts/bucket-admin/bucket-ico-font.eot?#iefix') format('embedded-opentype'),
    url('https://s3.amazonaws.com/springbot-assets/fonts/bucket-admin/bucket-ico-font.woff') format('woff'),
    url('https://s3.amazonaws.com/springbot-assets/fonts/bucket-admin/bucket-ico-font.ttf') format('truetype'),
    url('https://s3.amazonaws.com/springbot-assets/fonts/bucket-admin/bucket-ico-font.svg#bucket-ico-font') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="ico-"], [class*=" ico-"] {
  font-family: 'bucket-ico-font';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-sunrise:before {
  content: "\e600";
}
.ico-sun:before {
  content: "\e601";
}
.ico-moon:before {
  content: "\e602";
}
.ico-sun2:before {
  content: "\e603";
}
.ico-windy:before {
  content: "\e604";
}
.ico-wind:before {
  content: "\e605";
}
.ico-snowflake:before {
  content: "\e606";
}
.ico-cloudy:before {
  content: "\e607";
}
.ico-cloud:before {
  content: "\e608";
}
.ico-weather:before {
  content: "\e609";
}
.ico-weather2:before {
  content: "\e60a";
}
.ico-weather3:before {
  content: "\e60b";
}
.ico-lines:before {
  content: "\e60c";
}
.ico-cloud2:before {
  content: "\e60d";
}
.ico-lightning:before {
  content: "\e60e";
}
.ico-lightning2:before {
  content: "\e60f";
}
.ico-rainy:before {
  content: "\e610";
}
.ico-rainy2:before {
  content: "\e611";
}
.ico-windy2:before {
  content: "\e612";
}
.ico-windy3:before {
  content: "\e613";
}
.ico-snowy:before {
  content: "\e614";
}
.ico-snowy2:before {
  content: "\e615";
}
.ico-snowy3:before {
  content: "\e616";
}
.ico-weather4:before {
  content: "\e617";
}
.ico-cloudy2:before {
  content: "\e618";
}
.ico-cloud3:before {
  content: "\e619";
}
.ico-lightning3:before {
  content: "\e61a";
}
.ico-sun3:before {
  content: "\e61b";
}
.ico-moon2:before {
  content: "\e61c";
}
.ico-cloudy3:before {
  content: "\e61d";
}
.ico-cloud4:before {
  content: "\e61e";
}
.ico-cloud5:before {
  content: "\e61f";
}
.ico-lightning4:before {
  content: "\e620";
}
.ico-rainy3:before {
  content: "\e621";
}
.ico-rainy4:before {
  content: "\e622";
}
.ico-windy4:before {
  content: "\e623";
}
.ico-windy5:before {
  content: "\e624";
}
.ico-snowy4:before {
  content: "\e625";
}
.ico-snowy5:before {
  content: "\e626";
}
.ico-weather5:before {
  content: "\e627";
}
.ico-cloudy4:before {
  content: "\e628";
}
.ico-lightning5:before {
  content: "\e629";
}
.ico-thermometer:before {
  content: "\e62a";
}
.ico-compass:before {
  content: "\e62b";
}
.ico-none:before {
  content: "\e62c";
}
.ico-Celsius:before {
  content: "\e62d";
}
.ico-Fahrenheit:before {
  content: "\e62e";
}
.ico-office:before {
  content: "\e00b";
}
.ico-home:before {
  content: "\e006";
}
.ico-home2:before {
  content: "\e007";
}
.ico-newspaper:before {
  content: "\e00c";
}
.ico-pencil:before {
  content: "\e00d";
}
.ico-quill:before {
  content: "\e013";
}
.ico-quill2:before {
  content: "\e014";
}
.ico-quill3:before {
  content: "\e015";
}
.ico-pen:before {
  content: "\e016";
}
.ico-pen2:before {
  content: "\e018";
}
.ico-pen3:before {
  content: "\e019";
}
.ico-pen4:before {
  content: "\e01a";
}
.ico-palette:before {
  content: "\e022";
}
.ico-palette2:before {
  content: "\e021";
}
.ico-home3:before {
  content: "\e01c";
}
.ico-droplet:before {
  content: "\e025";
}
.ico-droplet2:before {
  content: "\e026";
}
.ico-droplet3:before {
  content: "\e027";
}
.ico-paint-format:before {
  content: "\e029";
}
.ico-image:before {
  content: "\e02b";
}
.ico-images:before {
  content: "\e02e";
}
.ico-image2:before {
  content: "\e02d";
}
.ico-image3:before {
  content: "\e031";
}
.ico-images2:before {
  content: "\e032";
}
.ico-image4:before {
  content: "\e033";
}
.ico-camera:before {
  content: "\e034";
}
.ico-camera2:before {
  content: "\e036";
}
.ico-music:before {
  content: "\e038";
}
.ico-music2:before {
  content: "\e03c";
}
.ico-guitar:before {
  content: "\e03f";
}
.ico-headphones:before {
  content: "\e040";
}
.ico-headphones2:before {
  content: "\e041";
}
.ico-play:before {
  content: "\e042";
}
.ico-movie:before {
  content: "\e044";
}
.ico-movie2:before {
  content: "\e045";
}
.ico-movie3:before {
  content: "\e046";
}
.ico-film:before {
  content: "\e047";
}
.ico-film2:before {
  content: "\e04a";
}
.ico-camera3:before {
  content: "\e04b";
}
.ico-gamepad:before {
  content: "\e051";
}
.ico-gamepad2:before {
  content: "\e053";
}
.ico-pacman:before {
  content: "\e054";
}
.ico-knight:before {
  content: "\e05c";
}
.ico-queen:before {
  content: "\e059";
}
.ico-bullhorn:before {
  content: "\e05f";
}
.ico-radio:before {
  content: "\e068";
}
.ico-new:before {
  content: "\e061";
}
.ico-mic:before {
  content: "\e06d";
}
.ico-mic2:before {
  content: "\e06e";
}
.ico-books:before {
  content: "\e072";
}
.ico-graduation:before {
  content: "\e076";
}
.ico-profile:before {
  content: "\e078";
}
.ico-basket:before {
  content: "\e0ce";
}
.ico-basket2:before {
  content: "\e0cf";
}
.ico-bag:before {
  content: "\e0d1";
}
.ico-bag2:before {
  content: "\e0d2";
}
.ico-coins:before {
  content: "\e0d4";
}
.ico-coin:before {
  content: "\e0d3";
}
.ico-support:before {
  content: "\e0d9";
}
.ico-phone:before {
  content: "\e0da";
}
.ico-cart-remove:before {
  content: "\e0cd";
}
.ico-cart-checkout:before {
  content: "\e0cc";
}
.ico-cart-plus:before {
  content: "\e0c8";
}
.ico-cart-minus:before {
  content: "\e0c9";
}
.ico-pushpin:before {
  content: "\e0f0";
}
.ico-clock:before {
  content: "\e101";
}
.ico-clock2:before {
  content: "\e107";
}
.ico-alarm:before {
  content: "\e109";
}
.ico-alarm2:before {
  content: "\e10a";
}
.ico-clock3:before {
  content: "\e108";
}
.ico-stopwatch:before {
  content: "\e111";
}
.ico-calendar:before {
  content: "\e112";
}
.ico-calendar2:before {
  content: "\e113";
}
.ico-calendar3:before {
  content: "\e114";
}
.ico-calendar4:before {
  content: "\e115";
}
.ico-print:before {
  content: "\e117";
}
.ico-print2:before {
  content: "\e118";
}
.ico-print3:before {
  content: "\e119";
}
.ico-mouse:before {
  content: "\e11b";
}
.ico-mouse2:before {
  content: "\e11c";
}
.ico-mouse3:before {
  content: "\e11d";
}
.ico-tv:before {
  content: "\e129";
}
.ico-archive:before {
  content: "\e12b";
}
.ico-cabinet:before {
  content: "\e12a";
}
.ico-mobile:before {
  content: "\e125";
}
.ico-laptop:before {
  content: "\e124";
}
.ico-screen:before {
  content: "\e123";
}
.ico-screen2:before {
  content: "\e122";
}
.ico-screen3:before {
  content: "\e121";
}
.ico-screen4:before {
  content: "\e120";
}
.ico-mobile2:before {
  content: "\e126";
}
.ico-tablet:before {
  content: "\e127";
}
.ico-tag:before {
  content: "\e0bc";
}
.ico-qrcode:before {
  content: "\e0bf";
}
.ico-barcode:before {
  content: "\e0be";
}
.ico-barcode2:before {
  content: "\e0bd";
}
.ico-cc:before {
  content: "\e0b2";
}
.ico-certificate:before {
  content: "\e0b1";
}
.ico-ticket:before {
  content: "\e0c0";
}
.ico-tag2:before {
  content: "\e0b7";
}
.ico-tags:before {
  content: "\e0bb";
}
.ico-tags2:before {
  content: "\e0ba";
}
.ico-copy:before {
  content: "\e091";
}
.ico-copy2:before {
  content: "\e090";
}
.ico-paste:before {
  content: "\e093";
}
.ico-paste2:before {
  content: "\e092";
}
.ico-paste3:before {
  content: "\e094";
}
.ico-stack:before {
  content: "\e095";
}
.ico-file-remove:before {
  content: "\e08c";
}
.ico-file-check:before {
  content: "\e08b";
}
.ico-file-remove2:before {
  content: "\e084";
}
.ico-file:before {
  content: "\e086";
}
.ico-file2:before {
  content: "\e07c";
}
.ico-file3:before {
  content: "\e07d";
}
.ico-files:before {
  content: "\e07e";
}
.ico-envelop:before {
  content: "\e0eb";
}
.ico-envelop2:before {
  content: "\e0ec";
}
.ico-envelop-opened:before {
  content: "\e0ee";
}
.ico-compass2:before {
  content: "\e0f9";
}
.ico-location:before {
  content: "\e0f3";
}
.ico-location2:before {
  content: "\e0f4";
}
.ico-bell:before {
  content: "\e10b";
}
.ico-direction:before {
  content: "\e0fe";
}
.ico-alarm-check:before {
  content: "\e10f";
}
.ico-alarm-cancel:before {
  content: "\e110";
}
.ico-alarm-plus:before {
  content: "\e10d";
}
.ico-alarm-minus:before {
  content: "\e10e";
}
.ico-bell2:before {
  content: "\e10c";
}
.ico-drawer:before {
  content: "\e12c";
}
.ico-drawer2:before {
  content: "\e12d";
}
.ico-drawer3:before {
  content: "\e12e";
}
.ico-download:before {
  content: "\e132";
}
.ico-upload:before {
  content: "\e133";
}
.ico-disk:before {
  content: "\e134";
}
.ico-cd:before {
  content: "\e135";
}
.ico-database:before {
  content: "\e138";
}
.ico-undo:before {
  content: "\e14b";
}
.ico-redo:before {
  content: "\e14c";
}
.ico-forward:before {
  content: "\e14d";
}
.ico-reply:before {
  content: "\e14e";
}
.ico-reply2:before {
  content: "\e14f";
}
.ico-bubble:before {
  content: "\e150";
}
.ico-bubbles:before {
  content: "\e151";
}
.ico-bubbles2:before {
  content: "\e154";
}
.ico-bubbles3:before {
  content: "\e155";
}
.ico-bubble-notification:before {
  content: "\e156";
}
.ico-user:before {
  content: "\e185";
}
.ico-users:before {
  content: "\e186";
}
.ico-user-plus:before {
  content: "\e187";
}
.ico-user-plus2:before {
  content: "\e188";
}
.ico-user-minus:before {
  content: "\e18a";
}
.ico-user-minus2:before {
  content: "\e189";
}
.ico-user-cancel:before {
  content: "\e18b";
}
.ico-user-block:before {
  content: "\e18c";
}
.ico-users2:before {
  content: "\e18d";
}
.ico-busy:before {
  content: "\e1a3";
}
.ico-quotes-left:before {
  content: "\e19f";
}
.ico-quotes-right:before {
  content: "\e1a0";
}
.ico-users3:before {
  content: "\e19b";
}
.ico-vcard:before {
  content: "\e19c";
}
.ico-tshirt:before {
  content: "\e19d";
}
.ico-busy2:before {
  content: "\e1a4";
}
.ico-busy3:before {
  content: "\e1a6";
}
.ico-spinner:before {
  content: "\e1a7";
}
.ico-spinner2:before {
  content: "\e1a8";
}
.ico-spinner3:before {
  content: "\e1b1";
}
.ico-microscope:before {
  content: "\e1b3";
}
.ico-spinner4:before {
  content: "\e1b0";
}
.ico-binoculars:before {
  content: "\e1b5";
}
.ico-zoom-in:before {
  content: "\e1b8";
}
.ico-search:before {
  content: "\e1b7";
}
.ico-zoom-out:before {
  content: "\e1b9";
}
.ico-search2:before {
  content: "\e1ba";
}
.ico-search3:before {
  content: "\e1bb";
}
.ico-search4:before {
  content: "\e1be";
}
.ico-zoom-in2:before {
  content: "\e1bc";
}
.ico-zoom-out2:before {
  content: "\e1bd";
}
.ico-expand:before {
  content: "\e1c8";
}
.ico-key:before {
  content: "\e1ca";
}
.ico-key2:before {
  content: "\e1cd";
}
.ico-key3:before {
  content: "\e1cc";
}
.ico-key4:before {
  content: "\e1ce";
}
.ico-keyhole:before {
  content: "\e1cf";
}
.ico-lock:before {
  content: "\e1d1";
}
.ico-cog:before {
  content: "\e1df";
}
.ico-equalizer:before {
  content: "\e1dd";
}
.ico-tools:before {
  content: "\e1e9";
}
.ico-cogs:before {
  content: "\e1e0";
}
.ico-equalizer2:before {
  content: "\e1dc";
}
.ico-wand:before {
  content: "\e1ec";
}
.ico-wand2:before {
  content: "\e1ed";
}
.ico-health:before {
  content: "\e1ee";
}
.ico-aid:before {
  content: "\e1ef";
}
.ico-bug:before {
  content: "\e1f1";
}
.ico-bug2:before {
  content: "\e1f2";
}
.ico-patch:before {
  content: "\e1f0";
}
.ico-pie:before {
  content: "\e1f7";
}
.ico-pie2:before {
  content: "\e1f8";
}
.ico-pie3:before {
  content: "\e1f9";
}
.ico-pie4:before {
  content: "\e1fa";
}
.ico-pie5:before {
  content: "\e1fb";
}
.ico-pie6:before {
  content: "\e1fc";
}
.ico-pie7:before {
  content: "\e1fd";
}
.ico-stats:before {
  content: "\e1fe";
}
.ico-stats2:before {
  content: "\e1ff";
}
.ico-stats3:before {
  content: "\e200";
}
.ico-bars:before {
  content: "\e201";
}
.ico-bars2:before {
  content: "\e203";
}
.ico-bars3:before {
  content: "\e204";
}
.ico-bars4:before {
  content: "\e205";
}
.ico-bars5:before {
  content: "\e206";
}
.ico-stats-up:before {
  content: "\e207";
}
.ico-stats-down:before {
  content: "\e208";
}
.ico-stairs-down:before {
  content: "\e209";
}
.ico-stairs-down2:before {
  content: "\e20a";
}
.ico-chart:before {
  content: "\e20b";
}
.ico-ladder:before {
  content: "\e20e";
}
.ico-cake:before {
  content: "\e20f";
}
.ico-gift:before {
  content: "\e210";
}
.ico-gift2:before {
  content: "\e211";
}
.ico-balloon:before {
  content: "\e212";
}
.ico-podium:before {
  content: "\e216";
}
.ico-medal:before {
  content: "\e218";
}
.ico-medal2:before {
  content: "\e217";
}
.ico-food:before {
  content: "\e227";
}
.ico-glass:before {
  content: "\e223";
}
.ico-glass2:before {
  content: "\e222";
}
.ico-trophy:before {
  content: "\e21e";
}
.ico-trophy2:before {
  content: "\e21d";
}
.ico-crown:before {
  content: "\e21c";
}
.ico-food2:before {
  content: "\e228";
}
.ico-hamburger:before {
  content: "\e229";
}
.ico-leaf:before {
  content: "\e22c";
}
.ico-leaf2:before {
  content: "\e22d";
}
.ico-apple-fruit:before {
  content: "\e22e";
}
.ico-tree:before {
  content: "\e22f";
}
.ico-meter:before {
  content: "\e235";
}
.ico-meter2:before {
  content: "\e236";
}
.ico-meter-slow:before {
  content: "\e237";
}
.ico-meter-medium:before {
  content: "\e238";
}
.ico-dashboard:before {
  content: "\e23a";
}
.ico-meter-fast:before {
  content: "\e239";
}
.ico-hammer:before {
  content: "\e23b";
}
.ico-balance:before {
  content: "\e23c";
}
.ico-bomb:before {
  content: "\e23d";
}
.ico-lab:before {
  content: "\e240";
}
.ico-magnet:before {
  content: "\e243";
}
.ico-magnet2:before {
  content: "\e244";
}
.ico-remove:before {
  content: "\e24f";
}
.ico-lamp:before {
  content: "\e24c";
}
.ico-lamp2:before {
  content: "\e24b";
}
.ico-lamp3:before {
  content: "\e24d";
}
.ico-remove2:before {
  content: "\e253";
}
.ico-remove3:before {
  content: "\e251";
}
.ico-remove4:before {
  content: "\e256";
}
.ico-briefcase:before {
  content: "\e257";
}
.ico-paper-plane:before {
  content: "\e25c";
}
.ico-airplane:before {
  content: "\e25a";
}
.ico-bus:before {
  content: "\e25f";
}
.ico-gas-pump:before {
  content: "\e25e";
}
.ico-truck:before {
  content: "\e260";
}
.ico-car:before {
  content: "\e25d";
}
.ico-bike:before {
  content: "\e261";
}
.ico-train:before {
  content: "\e263";
}
.ico-ship:before {
  content: "\e264";
}
.ico-cube:before {
  content: "\e268";
}
.ico-puzzle:before {
  content: "\e26e";
}
.ico-puzzle2:before {
  content: "\e270";
}
.ico-puzzle3:before {
  content: "\e271";
}
.ico-puzzle4:before {
  content: "\e26f";
}
.ico-sun-glasses:before {
  content: "\e275";
}
.ico-accessibility:before {
  content: "\e277";
}
.ico-brain:before {
  content: "\e278";
}
.ico-target:before {
  content: "\e279";
}
.ico-target2:before {
  content: "\e27b";
}
.ico-gun:before {
  content: "\e27c";
}
.ico-gun-ban:before {
  content: "\e27d";
}
.ico-shield:before {
  content: "\e27e";
}
.ico-shield2:before {
  content: "\e280";
}
.ico-shield3:before {
  content: "\e281";
}
.ico-soccer:before {
  content: "\e282";
}
.ico-football:before {
  content: "\e283";
}
.ico-baseball:before {
  content: "\e284";
}
.ico-basketball:before {
  content: "\e285";
}
.ico-hockey:before {
  content: "\e287";
}
.ico-eight-ball:before {
  content: "\e289";
}
.ico-bowling:before {
  content: "\e28b";
}
.ico-power:before {
  content: "\e28e";
}
.ico-switch:before {
  content: "\e290";
}
.ico-power-cord:before {
  content: "\e291";
}
.ico-cord:before {
  content: "\e292";
}
.ico-clipboard:before {
  content: "\e298";
}
.ico-signup:before {
  content: "\e296";
}
.ico-tree2:before {
  content: "\e2a6";
}
.ico-menu:before {
  content: "\e2ac";
}
.ico-grid:before {
  content: "\e2a5";
}
.ico-cloud-upload:before {
  content: "\e2b7";
}
.ico-cloud-download:before {
  content: "\e2b6";
}
.ico-cloud6:before {
  content: "\e2b5";
}
.ico-cloud7:before {
  content: "\e2b4";
}
.ico-menu2:before {
  content: "\e2ad";
}
.ico-menu3:before {
  content: "\e2b2";
}
.ico-download2:before {
  content: "\e2c0";
}
.ico-upload2:before {
  content: "\e2c1";
}
.ico-download3:before {
  content: "\e2b8";
}
.ico-upload3:before {
  content: "\e2b9";
}
.ico-earth:before {
  content: "\e2c7";
}
.ico-network:before {
  content: "\e2c8";
}
.ico-link:before {
  content: "\e2c9";
}
.ico-link2:before {
  content: "\e2ce";
}
.ico-flag:before {
  content: "\e2d5";
}
.ico-flag2:before {
  content: "\e2d4";
}
.ico-flag3:before {
  content: "\e2d1";
}
.ico-anchor:before {
  content: "\e2d0";
}
.ico-eye-blocked:before {
  content: "\e2da";
}
.ico-eye:before {
  content: "\e2d9";
}
.ico-bookmark:before {
  content: "\e2e3";
}
.ico-bookmark2:before {
  content: "\e2e4";
}
.ico-bookmarks:before {
  content: "\e2e5";
}
.ico-bookmark3:before {
  content: "\e2e6";
}
.ico-sun4:before {
  content: "\e2f3";
}
.ico-umbrella:before {
  content: "\e2f1";
}
.ico-starburst:before {
  content: "\e2e8";
}
.ico-temperature:before {
  content: "\e2ea";
}
.ico-weather-lightning:before {
  content: "\e2ec";
}
.ico-weather-rain:before {
  content: "\e2ed";
}
.ico-weather-snow:before {
  content: "\e2ee";
}
.ico-brightness-contrast:before {
  content: "\e2f7";
}
.ico-contrast:before {
  content: "\e2f8";
}
.ico-star:before {
  content: "\e2fd";
}
.ico-star2:before {
  content: "\e2fc";
}
.ico-star3:before {
  content: "\e2fe";
}
.ico-star4:before {
  content: "\e2ff";
}
.ico-star5:before {
  content: "\e300";
}
.ico-star6:before {
  content: "\e301";
}
.ico-heart:before {
  content: "\e307";
}
.ico-heart2:before {
  content: "\e308";
}
.ico-heart3:before {
  content: "\e30a";
}
.ico-heart4:before {
  content: "\e30b";
}
.ico-thumbs-up:before {
  content: "\e30f";
}
.ico-thumbs-up2:before {
  content: "\e310";
}
.ico-thumbs-down:before {
  content: "\e311";
}
.ico-thumbs-down2:before {
  content: "\e312";
}
.ico-thumbs-up3:before {
  content: "\e313";
}
.ico-thumbs-up4:before {
  content: "\e314";
}
.ico-happy:before {
  content: "\e31e";
}
.ico-happy2:before {
  content: "\e31f";
}
.ico-smiley:before {
  content: "\e320";
}
.ico-smiley2:before {
  content: "\e321";
}
.ico-tongue:before {
  content: "\e322";
}
.ico-tongue2:before {
  content: "\e323";
}
.ico-sad:before {
  content: "\e324";
}
.ico-sad2:before {
  content: "\e325";
}
.ico-wink:before {
  content: "\e326";
}
.ico-grin:before {
  content: "\e328";
}
.ico-grin2:before {
  content: "\e329";
}
.ico-cool:before {
  content: "\e32a";
}
.ico-angry:before {
  content: "\e32c";
}
.ico-evil:before {
  content: "\e32e";
}
.ico-neutral:before {
  content: "\e334";
}
.ico-confused:before {
  content: "\e332";
}
.ico-shocked:before {
  content: "\e330";
}
.ico-move:before {
  content: "\e352";
}
.ico-resize:before {
  content: "\e353";
}
.ico-warning:before {
  content: "\e356";
}
.ico-notification:before {
  content: "\e357";
}
.ico-notification2:before {
  content: "\e358";
}
.ico-question:before {
  content: "\e359";
}
.ico-question2:before {
  content: "\e35a";
}
.ico-question3:before {
  content: "\e35b";
}
.ico-question4:before {
  content: "\e35c";
}
.ico-warning2:before {
  content: "\e355";
}
.ico-minus-circle:before {
  content: "\e360";
}
.ico-minus-circle2:before {
  content: "\e361";
}
.ico-info:before {
  content: "\e362";
}
.ico-info2:before {
  content: "\e363";
}
.ico-cancel-circle:before {
  content: "\e365";
}
.ico-cancel-circle2:before {
  content: "\e366";
}
.ico-checkmark-circle:before {
  content: "\e367";
}
.ico-checkmark-circle2:before {
  content: "\e368";
}
.ico-close:before {
  content: "\e36b";
}
.ico-close2:before {
  content: "\e36c";
}
.ico-close3:before {
  content: "\e36d";
}
.ico-close4:before {
  content: "\e36e";
}
.ico-checkmark:before {
  content: "\e372";
}
.ico-checkmark2:before {
  content: "\e370";
}
.ico-spell-check:before {
  content: "\e374";
}
.ico-minus:before {
  content: "\e375";
}
.ico-plus:before {
  content: "\e376";
}
.ico-play2:before {
  content: "\e380";
}
.ico-pause:before {
  content: "\e381";
}
.ico-stop:before {
  content: "\e382";
}
.ico-backward:before {
  content: "\e383";
}
.ico-forward2:before {
  content: "\e384";
}
.ico-play3:before {
  content: "\e385";
}
.ico-pause2:before {
  content: "\e386";
}
.ico-backward2:before {
  content: "\e388";
}
.ico-stop2:before {
  content: "\e387";
}
.ico-forward3:before {
  content: "\e389";
}
.ico-first:before {
  content: "\e38a";
}
.ico-last:before {
  content: "\e38b";
}
.ico-previous:before {
  content: "\e38c";
}
.ico-next:before {
  content: "\e38d";
}
.ico-eject:before {
  content: "\e38e";
}
.ico-volume-high:before {
  content: "\e38f";
}
.ico-volume-medium:before {
  content: "\e390";
}
.ico-volume-low:before {
  content: "\e391";
}
.ico-volume-mute:before {
  content: "\e392";
}
.ico-volume-mute2:before {
  content: "\e393";
}
.ico-volume-increase:before {
  content: "\e394";
}
.ico-volume-decrease:before {
  content: "\e395";
}
.ico-loop:before {
  content: "\e3a7";
}
.ico-loop2:before {
  content: "\e3a5";
}
.ico-volume-mute3:before {
  content: "\e3a4";
}
.ico-loop3:before {
  content: "\e3a8";
}
.ico-loop4:before {
  content: "\e3a9";
}
.ico-shuffle:before {
  content: "\e3aa";
}
.ico-arrow-up:before {
  content: "\e3b4";
}
.ico-arrow-right:before {
  content: "\e3b5";
}
.ico-arrow-down:before {
  content: "\e3b6";
}
.ico-arrow-left:before {
  content: "\e3b7";
}
.ico-arrow-up-left:before {
  content: "\e3c8";
}
.ico-arrow-up2:before {
  content: "\e3c9";
}
.ico-arrow-up-right:before {
  content: "\e3ca";
}
.ico-arrow-right2:before {
  content: "\e3cb";
}
.ico-arrow-left2:before {
  content: "\e3cf";
}
.ico-arrow-down-left:before {
  content: "\e3ce";
}
.ico-arrow-down2:before {
  content: "\e3cd";
}
.ico-arrow-down-right:before {
  content: "\e3cc";
}
.ico-arrow-up3:before {
  content: "\e40c";
}
.ico-arrow-right3:before {
  content: "\e40d";
}
.ico-arrow-down3:before {
  content: "\e40e";
}
.ico-arrow-left3:before {
  content: "\e40f";
}
.ico-menu4:before {
  content: "\e418";
}
.ico-arrow-up4:before {
  content: "\e414";
}
.ico-arrow-right4:before {
  content: "\e415";
}
.ico-arrow-down4:before {
  content: "\e416";
}
.ico-arrow-left4:before {
  content: "\e417";
}
.ico-checkbox:before {
  content: "\e432";
}
.ico-checkbox-partial:before {
  content: "\e431";
}
.ico-checkbox-unchecked:before {
  content: "\e433";
}
.ico-checkbox-partial2:before {
  content: "\e434";
}
.ico-checkbox-unchecked2:before {
  content: "\e42f";
}
.ico-square:before {
  content: "\e430";
}
.ico-checkbox-checked:before {
  content: "\e42e";
}
.ico-radio-checked:before {
  content: "\e438";
}
.ico-radio-unchecked:before {
  content: "\e439";
}
.ico-circle:before {
  content: "\e43a";
}
.ico-filter:before {
  content: "\e443";
}
.ico-scissors:before {
  content: "\e442";
}
.ico-filter2:before {
  content: "\e445";
}
.ico-filter3:before {
  content: "\e446";
}
.ico-font:before {
  content: "\e447";
}
.ico-font-size:before {
  content: "\e448";
}
.ico-type:before {
  content: "\e449";
}
.ico-text-height:before {
  content: "\e44a";
}
.ico-scissors2:before {
  content: "\e441";
}
.ico-vector:before {
  content: "\e43e";
}
.ico-crop:before {
  content: "\e43d";
}
.ico-crop2:before {
  content: "\e43c";
}
.ico-text-width:before {
  content: "\e44b";
}
.ico-strikethrough:before {
  content: "\e452";
}
.ico-font-size2:before {
  content: "\e453";
}
.ico-page-break:before {
  content: "\e45c";
}
.ico-table:before {
  content: "\e465";
}
.ico-table2:before {
  content: "\e466";
}
.ico-pilcrow:before {
  content: "\e468";
}
.ico-new-tab:before {
  content: "\e47d";
}
.ico-new-tab2:before {
  content: "\e47c";
}
.ico-embed:before {
  content: "\e47f";
}
.ico-code:before {
  content: "\e480";
}
.ico-console:before {
  content: "\e481";
}
.ico-share:before {
  content: "\e48c";
}
.ico-google-plus:before {
  content: "\e494";
}
.ico-google-plus2:before {
  content: "\e495";
}
.ico-google-plus3:before {
  content: "\e496";
}
.ico-google-drive:before {
  content: "\e497";
}
.ico-facebook:before {
  content: "\e498";
}
.ico-facebook2:before {
  content: "\e499";
}
.ico-facebook3:before {
  content: "\e49a";
}
.ico-facebook4:before {
  content: "\e49b";
}
.ico-instagram:before {
  content: "\e49c";
}
.ico-twitter:before {
  content: "\e49d";
}
.ico-twitter2:before {
  content: "\e49e";
}
.ico-twitter3:before {
  content: "\e49f";
}
.ico-feed:before {
  content: "\e4a0";
}
.ico-feed2:before {
  content: "\e4a1";
}
.ico-feed3:before {
  content: "\e4a2";
}
.ico-youtube:before {
  content: "\e4a3";
}
.ico-youtube2:before {
  content: "\e4a4";
}
.ico-vimeo:before {
  content: "\e4a5";
}
.ico-vimeo2:before {
  content: "\e4a6";
}
.ico-vimeo3:before {
  content: "\e4a7";
}
.ico-lanyrd:before {
  content: "\e4a8";
}
.ico-flickr:before {
  content: "\e4a9";
}
.ico-flickr2:before {
  content: "\e4aa";
}
.ico-flickr3:before {
  content: "\e4ab";
}
.ico-flickr4:before {
  content: "\e4ac";
}
.ico-picassa:before {
  content: "\e4ad";
}
.ico-picassa2:before {
  content: "\e4ae";
}
.ico-dribbble:before {
  content: "\e4af";
}
.ico-dribbble2:before {
  content: "\e4b0";
}
.ico-dribbble3:before {
  content: "\e4b1";
}
.ico-forrst:before {
  content: "\e4b2";
}
.ico-forrst2:before {
  content: "\e4b3";
}
.ico-deviantart:before {
  content: "\e4b4";
}
.ico-deviantart2:before {
  content: "\e4b5";
}
.ico-steam:before {
  content: "\e4b6";
}
.ico-steam2:before {
  content: "\e4b7";
}
.ico-github:before {
  content: "\e4b8";
}
.ico-github2:before {
  content: "\e4b9";
}
.ico-github3:before {
  content: "\e4ba";
}
.ico-github4:before {
  content: "\e4bb";
}
.ico-github5:before {
  content: "\e4bc";
}
.ico-wordpress:before {
  content: "\e4bd";
}
.ico-wordpress2:before {
  content: "\e4be";
}
.ico-joomla:before {
  content: "\e4bf";
}
.ico-blogger:before {
  content: "\e4c0";
}
.ico-blogger2:before {
  content: "\e4c1";
}
.ico-tumblr:before {
  content: "\e4c2";
}
.ico-tumblr2:before {
  content: "\e4c3";
}
.ico-yahoo:before {
  content: "\e4c4";
}
.ico-tux:before {
  content: "\e4c5";
}
.ico-apple:before {
  content: "\e4c6";
}
.ico-finder:before {
  content: "\e4c7";
}
.ico-android:before {
  content: "\e4c8";
}
.ico-windows:before {
  content: "\e4c9";
}
.ico-windows8:before {
  content: "\e4ca";
}
.ico-soundcloud:before {
  content: "\e4cb";
}
.ico-soundcloud2:before {
  content: "\e4cc";
}
.ico-skype:before {
  content: "\e4cd";
}
.ico-reddit:before {
  content: "\e4ce";
}
.ico-linkedin:before {
  content: "\e4cf";
}
.ico-lastfm:before {
  content: "\e4d0";
}
.ico-lastfm2:before {
  content: "\e4d1";
}
.ico-delicious:before {
  content: "\e4d2";
}
.ico-stumbleupon:before {
  content: "\e4d3";
}
.ico-stumbleupon2:before {
  content: "\e4d4";
}
.ico-stackoverflow:before {
  content: "\e4d5";
}
.ico-pinterest:before {
  content: "\e4d6";
}
.ico-pinterest2:before {
  content: "\e4d7";
}
.ico-xing:before {
  content: "\e4d8";
}
.ico-xing2:before {
  content: "\e4d9";
}
.ico-flattr:before {
  content: "\e4da";
}
.ico-foursquare:before {
  content: "\e4db";
}
.ico-foursquare2:before {
  content: "\e4dc";
}
.ico-paypal:before {
  content: "\e4dd";
}
.ico-paypal2:before {
  content: "\e4de";
}
.ico-paypal3:before {
  content: "\e4df";
}
.ico-html5:before {
  content: "\e4eb";
}
.ico-html52:before {
  content: "\e4ea";
}
.ico-file-xml:before {
  content: "\e4e8";
}
.ico-file-css:before {
  content: "\e4e9";
}
.ico-file-powerpoint:before {
  content: "\e4e7";
}
.ico-file-zip:before {
  content: "\e4e6";
}
.ico-file-excel:before {
  content: "\e4e5";
}
.ico-file-word:before {
  content: "\e4e4";
}
.ico-file-openoffice:before {
  content: "\e4e3";
}
.ico-file-pdf:before {
  content: "\e4e2";
}
.ico-libreoffice:before {
  content: "\e4e1";
}
.ico-yelp:before {
  content: "\e4e0";
}
.ico-css3:before {
  content: "\e4ec";
}
.ico-chrome:before {
  content: "\e4ed";
}
.ico-firefox:before {
  content: "\e4ee";
}
.ico-IE:before {
  content: "\e4ef";
}
.ico-opera:before {
  content: "\e4f0";
}
.ico-safari:before {
  content: "\e4f1";
}
.ico-IcoMoon:before {
  content: "\e4f2";
}
.ico-book:before {
  content: "\e071";
}
.ico-book2:before {
  content: "\e070";
}
