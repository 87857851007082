@import "./theme";
@import "./colors";

// These are custom classes meant for shared use.
// NO bootrap overrides or variables should be defined here.

//= Helper Classes

.subheader {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
}

.cursor-pointer {
  cursor: pointer;
}

.hover-blue {
  &:hover {
    color: $blue;
  }
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.object-fit-cover {
  object-fit: cover;
}

.size-25px {
  height: 25px !important;
  width: 25px !important;
}

.size-40px {
  height: 40px !important;
  width: 40px !important;
}

.size-50px {
  height: 50px !important;
  width: 50px !important;
}

.w-40 {
  width: 40% !important;
}

.w-10 {
  width: 10% !important;
}

.w-90 {
  width: 90% !important;
}

.object-position-center {
  object-position: center;
}

.object-fit-contain {
  object-fit: contain;
}

.cursor-grab {
  cursor: grab;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.bg-overlay {
  background: rgba(29, 29, 29, 0.3);
}

.border-grey-300 {
  border-color: $gray-300;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.underline {
  text-decoration: underline;
}

.no-text-decoration {
  text-decoration: none;
}

.slightly-smaller-text {
  font-size: $font-size-base * 0.875;
}

//= Shared Components

.full-page-loading-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1000;

  .cog-wrapper {
    text-align: center;
    margin-top: 45vh;
  }
}

.sidebar {
  height: 75vh;
}

// changes to baseline bootstrap theme disabled rounded, added back due to need
.rounded {
  border-radius: 4px !important;
}

.extra-rounded {
  border-radius: 10px;
}

.text-facebook {
  color: $facebook-blue !important;
}

.text-twitter {
  color: $twitter-blue !important;
}

// adds rainbow as background of icon
.text-instagram {
  color: #8134AF !important;
}

.full-width {
  width: 100%;
}

.sb-nav__sb-icon  {
  width: $sb-icon-size;
  height: $sb-icon-size;
  margin-top: $sb-icon-margin;
  margin-bottom: $header-height - $sb-icon-size - $sb-icon-margin;
}

.sb-router-outlet {
  min-height: calc(100vh - #{$header-and-footer-height});
  margin-left: $nav-width;
  margin-top: $header-height;

  @media (max-width: 768px) {
    min-height: calc(100vh - #{$header-and-footer-height} - $mobile-nav-buffer);
    margin-top: $header-height + $mobile-nav-buffer;
    margin-left: 0;
  }
}

.sb-router-outlet__no-nav {
  min-height: calc(100vh - #{$header-and-footer-height});
  margin-top: $header-height;

  @media (max-width: 768px) {
    margin-top: $header-height + $mobile-nav-buffer;
    margin-left: 0;
  }
}

.sb-router-outlet__no-header {
  min-height: calc(100vh - #{$footer-height});
}

.header-margin {
  margin-top: $header-height;
}

.show-layout-container {
  min-height: calc(100vh - #{$header-and-footer-height});

  .section {
    margin: auto;
    max-width: 800px;
    background-color: $white;
  }

  .bottom-section {
    max-width: 800px;
  }
}

// Hide the year on ngb-datepicker popover in segments.
.form-control__datepicker--birthday ~ ngb-datepicker [aria-label="Select year"] {
  display: none;
}

.checkbox-container {
  padding-right: 0px;
}

.checkbox input[type="checkbox"] {
  opacity: 0;
  display: none;
}

.checkbox label {
  position: relative;
  display: inline-block;
  /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
  padding-left: 24px;
  padding-top: 6px;
}

.checkbox label::before,
.checkbox label::after {
  position: absolute;
  content: "";
  /*Needed for the line-height to take effect*/
  display: inline-block;
}

/*Outer box of the fake checkbox*/
.checkbox label::before{
  height: 21px;
  width: 20px;
  border: 1px solid;
  left: 0px;
  border-color: #d3d3d3;
  border-radius: 2px;
  /*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
   *to vertically center it.
   */
  top: 5px;
}

/*Checkmark of the fake checkbox*/
.checkbox label::after {
  height: 9px;
  width: 14px;
  border-left: 2.5px solid;
  border-bottom: 2.5px solid;
  transform: rotate(-45deg);
  left: 3px;
  top: 9px;
  color: #00c04a;
}

/*Hide the checkmark by default*/
.checkbox input[type="checkbox"] + label::after {
  content: none;
}

/*Unhide on the checked state*/
.checkbox input[type="checkbox"]:checked + label::after {
  content: "";
}

/*Adding focus styles on the outer-box of the fake checkbox*/
.checkbox input[type="checkbox"]:focus + label::before {
  outline: rgb(59, 153, 252) auto 5px;
}

.card {
  box-sizing: border-box;
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.1);
  border: 1px solid $accent-gray;
  background: $white;
  border-radius: 4px;
}

.fixed-top {
  top: $header-height;
}

.close-top {
  top: 5px
}

.sb-tooltip {
  .tooltip-inner {
    background-color: $dark-blue;
    border-radius: 2px;
    padding: 5px 8px;
    text-align: left;
  }

  .arrow::before {
    border-top-color: $dark-blue;
  }
}

.subheader {
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
}

.ngx-slider-bar {
  height: 16px !important;
}

.ngx-slider-bar-wrapper {
  padding-top: 12px !important;
}

.ngx-slider-pointer {
  background-color: $green !important;
  border: solid 3px white !important;
  box-shadow: 0 3px 3px rgba(0,0,0,0.2) !important;
  outline: none !important;
}

.ngx-slider-pointer::after {
  display: none !important;
}

.ngx-slider-selection {
  background-color: $green !important;
  background: $green !important;
}

.ngx-slider-selected {
  background: $green !important;
}

.ngx-slider-tick-legend {
  top: 14px;

  hr {
    margin-top: 0;
    margin-bottom: 0;
    width: 1px;
    height: 14px;
    background: #d3d3d3;
  }
}

.dropdown-menu-full-width[x-placement^="top"],
.dropdown-menu-full-width[x-placement^="bottom"] {
  min-width: 100% !important;
}

$phone-screen-max: 767px;
$laptop-screen: 1024px;

.dash-section {
  max-width: 1280px;
  position: relative;
  margin: auto;
  font-family: 'Roboto', sans-serif;

  h1 {
    display: inline;
    color: #000000;
    font-family: 'Roboto', sans-serif;
    font-size: 28px;
    font-weight: 400;
    line-height: 34px;
  }

  .cta {
    width: 100%;
    text-align: center;
    margin: 10px;
    margin-top: 32px;

    @media (max-width: $phone-screen-max) {
      display: none;
    }
  }

  .updated-label {
    display: inline;
    margin-left: 12px;
    font-weight: 300;

    @media (max-width: $phone-screen-max) {
      display: block;
      margin-left: 0;
    }
  }
}

.date-range-selector {
  display: block;
  float: right;
  margin-right: 50px;

  margin-top: -8px;

  .btn-outline-dark {
    background-color: white !important;
  }

  .btn-outline-dark:hover {
    background-color: $text-gray !important;
  }

  @media (max-width: $laptop-screen) {
    margin-right: 0px;
    margin-top: 10px;
    float: none !important;
  }
}

channel-card {
  float: left;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
}

.subheader {
  margin-left: -15px;
  margin-right: -15px;
}

.subheader-button {
  margin-top: -8px;
  display: block;

  @media (max-width: $laptop-screen) {
    text-align: center;
    margin-top: 14px;
    float: none !important;
  }
}

.btn-sm {
  padding: 6px 10px;
  max-height: 34px;
}

.hidden {
  display: none !important;
}

.hide-on-mobile {
  @media (max-width: 768px) {
    display: none !important;
  }
}

.show-on-mobile {
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
}

.show-on-laptop {
  display: none !important;
  @media (min-width: 768px) {
    display: block !important;
  }
}

.hide-on-md {
  @media (max-width: 992px) {
    display: none !important;
  }
}

.show-on-md {
  display: none;
  @media (max-width: 992px) {
    display: block;
  }
}

.warning-notification-banner {
  position: fixed;
  width: 100%;
  z-index: 50;
  padding: 15px 0px 15px 12px;
  background-color: $gold-dark;
  color: white;
  font-size: 14px;
  font-weight: 300;

  @media screen and (min-width: 768px){
    max-width: calc(100vw - 120px);
  }

  @media screen and (max-width: 768px){
    top: 115px;
  }

  .exclamation-icon {
    color: #007299;
    background-color: white;
    border: 1px solid white;
    border-radius:50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;
  }

  .notification-bar-text {
    margin-left: 8px;

    .connect-cart-link {
      color: white;
      text-decoration: underline;
    }
  }
}

.dashboard-loading-box {
  background: $gray-100;
  display: inline-block;
}

.homepage-background {
  background: url('https://springbot-assets.s3.amazonaws.com/images/register-blob.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.fixed-errors-bottom {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: -68px;
  max-width: 100%;
  z-index: 999;
  font-size: 14px;
}

.fixed-message {
  padding: 10px;
  margin-top: 10px;
  text-align: center;
  &.error {
    background: #ffbaba;
  }
  &.success {
    background: $green;
  }
  &.warning {
    background: $yellow;
  }
}

.w-100-mobile {
  @media (max-width: 768px) {
    width: 100% !important;
  }
}

.hide-card-on-mobile {
  @media (max-width: 768px) {
    background-color: $catskill-white !important;
    background: $catskill-white !important;
    box-shadow: none !important;
    border: none;
    .card-body {
      padding: 0;
    }
  }
}

.mobile-preview-container {
  max-height: calc(100vh - ($header-height + $mobile-nav-buffer));
  overflow: hidden;
}

.mobile-preview-alert {
  position: absolute;
  top: 120px;
  left: 10px;
  right: 10px;
}

.mobile-preview-overlay {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.mobile-preview-content {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
}
