@import "src/assets/styles/theme";
@import '~@ctrl/ngx-emoji-mart/picker';

/* Importing Bootstrap SCSS file. */
@import "node_modules/bootstrap/scss/bootstrap";
@import "src/assets/styles/bootstrap-overrides";

@import "src/assets/styles/animations";
@import "src/assets/styles/fonts";
@import "src/assets/styles/modals";
@import "src/assets/styles/shared";
