.sb-modal {
  .modal-content {
    padding: 0 !important;
  }

  .sb-modal-content {
    padding-top: 15px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
  }
}

.sb-modal-lg {
  .modal-content {
    padding: 10px !important;
  }
}

.modal-close-icon {
  color: #c6c6c6;
  font-size: 22px;
  cursor: pointer;
}

.modal-content {
  padding-top: 15px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}

.modal-header-text {
  font-family: "Roboto";
  color: $text-gray;
  margin-bottom: 15px;
  text-align: center;
}

.modal-warning-text {
  font-family: "Roboto";
  color: $yellow;
  text-align: center;
}

.modal-error-text {
  font-family: "Roboto";
  color: $red;
  text-align: center;
}

.modal-icon-wrapper {
  text-align: center;
}

.modal-icon {
  height: 64px;
  width: 64px;
}

.modal-actions {
  // background: $blue-modal-bottom;
  text-align: center;
}

.modal-action {
  height: 40px !important;
  width: 136px;
  margin: 15px;
}

.modal-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);

  i {
    top: 40%;
    position: absolute;
  }
}

.sb-dynamic-segments-modal {
  .modal-dialog {
    max-width: 600px !important;
  }
  .modal-content {
    width: 600px !important;
  }
}

.sb-resend-email-modal {
  .modal-dialog {
    max-width: 600px !important;
  }
}

.modal-content {
  padding: 0 0 0 0 !important;
}
