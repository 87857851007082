@import "./theme";

//= Green
.green-color { color: $green !important; }
.green-background { background: $green !important; }
.green-border { border-color: $green !important; }

.green-light-color { color: $green-light !important; }
.green-light-background { background: $green-light !important; }
.green-light-border { border-color: $green-light !important; }

.green-medium-color { color: $green-medium !important; }
.green-medium-background { background: $green-medium !important; }
.green-medium-border { border-color: $green-medium !important; }

.green-dark-color { color: $green-dark !important; }
.green-dark-background { background: $green-dark !important; }
.green-dark-border { border-color: $green-dark !important; }

//= Blue
.blue-color { color: $blue !important; }
.blue-background { background: $blue !important; }
.blue-border { border-color: $blue !important; }

.blue-light-color { color: $blue-light !important; }
.blue-light-background { background: $blue-light !important; }
.blue-light-border { border-color: $blue-light !important; }

.blue-medium-color { color: $blue-medium !important; }
.blue-medium-background { background: $blue-medium !important; }
.blue-medium-border { border-color: $blue-medium !important; }

.blue-dark-color { color: $blue-dark !important; }
.blue-dark-background { background: $blue-dark !important; }
.blue-dark-border { border-color: $blue-dark !important; }

//= Glacier
.glacier-color { color: $glacier !important; }
.glacier-background { background: $glacier !important; }
.glacier-border { border-color: $glacier !important; }

.glacier-light-color { color: $glacier-light !important; }
.glacier-light-background { background: $glacier-light !important; }
.glacier-light-border { border-color: $glacier-light !important; }

.glacier-medium-color { color: $glacier-medium !important; }
.glacier-medium-background { background: $glacier-medium !important; }
.glacier-medium-border { border-color: $glacier-medium !important; }

.glacier-dark-color { color: $glacier-dark !important; }
.glacier-dark-background { background: $glacier-dark !important; }
.glacier-dark-border { border-color: $glacier-dark !important; }

//= Crimson
.crimson-color { color: $crimson !important; }
.crimson-background { background: $crimson !important; }
.crimson-border { border-color: $crimson !important; }

.crimson-light-color { color: $crimson-light !important; }
.crimson-light-background { background: $crimson-light !important; }
.crimson-light-border { border-color: $crimson-light !important; }

.crimson-medium-color { color: $crimson-medium !important; }
.crimson-medium-background { background: $crimson-medium !important; }
.crimson-medium-border { border-color: $crimson-medium !important; }

.crimson-dark-color { color: $crimson-dark !important; }
.crimson-dark-background { background: $crimson-dark !important; }
.crimson-dark-border { border-color: $crimson-dark !important; }

//= Gold
.gold-color { color: $gold !important; }
.gold-background { background: $gold !important; }
.gold-border { border-color: $gold !important; }

.gold-light-color { color: $gold-light !important; }
.gold-light-background { background: $gold-light !important; }
.gold-light-border { border-color: $gold-light !important; }

.gold-medium-color { color: $gold-medium !important; }
.gold-medium-background { background: $gold-medium !important; }
.gold-medium-border { border-color: $gold-medium !important; }

.gold-dark-color { color: $gold-dark !important; }
.gold-dark-background { background: $gold-dark !important; }
.gold-dark-border { border-color: $gold-dark !important; }

//= Black
.black-color { color: $black !important; }
.black-background { background: $black !important; }
.black-border { border-color: $black !important; }

//= White
.white-color { color: $white !important; }
.white-background { background: $white !important; }
.white-border { border-color: $white !important; }

//= Catskill White
.catskill-white-color { color: $catskill-white !important; }
.catskill-white-background { background: $catskill-white !important; }
.catskill-white-border { border-color: $catskill-white !important; }

//= Text Gray
.text-gray-color { color: $text-gray !important; }
.text-gray-background { background: $text-gray !important; }
.text-gray-border { border-color: $text-gray !important; }

//= Medium Gray
.medium-gray-color { color: $medium-gray !important; }
.medium-gray-background { background: $medium-gray !important; }
.medium-gray-border { border-color: $medium-gray !important; }

//= Accent Gray
.accent-gray-color { color: $accent-gray !important; }
.accent-gray-background { background: $accent-gray !important; }
.accent-gray-border { border-color: $accent-gray !important; }

//= Light Gray
.light-gray-color { color: $light-gray !important; }
.light-gray-background { background: $light-gray !important; }
.light-gray-border { border-color: $light-gray !important; }

//= Blue Gray
.blue-gray-color { color: $blue-gray !important; }
.blue-gray-background { background: $blue-gray !important; }
.blue-gray-border { border-color: $blue-gray !important; }

.transparent-color { color: #FFF0 !important }
